import { login, logout, getInfo, loginGoogle, refreshAccessToken } from '@/api/user'
import {
    getToken,
    setToken,
    removeToken,
    setTokenExpiration,
    removeTokenExpiration,
    setRefreshToken,
    removeRefreshToken
} from '@/utils/auth'
// import { signInGoogle } from '@/api/google'
import { resetRouter } from '@/router'
import VueJwtDecode from 'vue-jwt-decode'

const state = {
    token: getToken(),
    user_info: {},
    roles: [],
    user_id: '',
    is_pw_temp: ''
}

const mutations = {
    SET_TOKEN: (state, token) => {
        state.token = token
    },
    SET_USER_INFO: (state, user_info) => {
        state.user_info = user_info
    },
    SET_ROLES: (state, roles) => {
        state.roles = roles
    },
    SET_USER_ID: (state, user_id) => {
        state.user_id = user_id
    },
    SET_PW_TEMP: (state, is_pw_temp) => {
        state.is_pw_temp = is_pw_temp
    }
}

const actions = {
    // user login
    login({ commit }, userInfo) {
        const { username, password, fingerprint, app_id } = userInfo
        return new Promise((resolve, reject) => {
            login({
                username: username.trim(),
                password: password,
                fingerprint: fingerprint,
                app_id: app_id
            })
                .then((response) => {
                    const data = response.data
                    commit('SET_TOKEN', data.data.token)
                    setToken(data.data.token)
                    setRefreshToken(data.data.refresh)

                    const decodedJwtToken = VueJwtDecode.decode(data.data.token) // decode jwt token

                    setTokenExpiration(decodedJwtToken.exp)

                    getInfo(decodedJwtToken.sys_id)
                        .then((response) => {
                            const data = response.data.data[0]
                            resolve({
                                is_pw_temp: data.is_pw_temp,
                                sso: data.sso
                            })
                        })
                        .catch((error) => {
                            reject(error)
                        })
                })
                .catch((error) => {
                    reject(error)
                })
        })
    },

    loginGoogle({ commit }, userInfo) {
        const { token, fingerprint, app_id } = userInfo
        return new Promise((resolve, reject) => {
            loginGoogle({ token: token, fingerprint: fingerprint, app_id: app_id })
                .then((response) => {
                    const data = response.data
                    commit('SET_TOKEN', data.data.data.generated_token)
                    setToken(data.data.data.generated_token)
                    setRefreshToken(data.data.data.refresh_token)

                    const decodedJwtToken = VueJwtDecode.decode(data.data.data.generated_token) // decode jwt token

                    setTokenExpiration(decodedJwtToken.exp)

                    resolve()
                })
                .catch((error) => {
                    reject(error)
                })
        })
    },

    // get user info
    // Invoked upon route change
    getInfo({ commit, state }) {
        return new Promise((resolve, reject) => {
            const decodedJwtToken = VueJwtDecode.decode(state.token) // decode jwt token
            getInfo(decodedJwtToken.sys_id)
                .then((response) => {
                    const data = response.data.data[0]
                    if (!data) {
                        reject('Verification failed, please Login again.')
                    }

                    var roles = decodedJwtToken.roles

                    // roles must be a non-empty array
                    if (!roles || roles.length == 0) {
                        reject('getInfo: roles must be a non-null array!')
                    }

                    commit('SET_ROLES', roles)
                    commit('SET_USER_INFO', data)
                    commit('SET_USER_ID', decodedJwtToken.sys_id)
                    commit('SET_PW_TEMP', data.is_pw_temp)
                    resolve({
                        roles: roles
                    })
                })
                .catch((error) => {
                    reject(error)
                })
        })
    },

    // refresh token
    refreshToken({ commit }, token) {
        return new Promise((resolve, reject) => {
            refreshAccessToken({ token })
                .then((response) => {
                    resolve({
                        response: response.data
                    })
                })
                .catch((error) => {
                    reject(error)
                })
        })
    },

    // user logout
    logout({ commit, state }) {
        return new Promise((resolve, reject) => {
            localStorage.clear()
            commit('SET_TOKEN', '')
            commit('SET_USER_ID', [])
            commit('SET_ROLES', [])
            commit('SET_USER_INFO', {})
            removeToken()
            removeTokenExpiration()
            removeRefreshToken()
            resetRouter()
            resolve()
        })
    },

    // user logoutAPI
    logoutApi() {
        logout()
    },

    // remove token
    resetToken({ commit }) {
        return new Promise((resolve) => {
            commit('SET_TOKEN', '')
            commit('SET_USER_ID', [])
            commit('SET_ROLES', [])
            commit('SET_USER_INFO', {})
            removeToken()
            removeTokenExpiration()
            removeRefreshToken()
            resetRouter()
            resolve()
        })
    }
}

export default {
    namespaced: true,
    state,
    mutations,
    actions
}
