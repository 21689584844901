import Cookies from 'js-cookie'
import { getApiUrl } from '@/utils/auth'
const api_url = getApiUrl()

const state = {
    app_api_domain: api_url.domain,
    app_base_api: api_url.base_api,
    app_environment: api_url.environment,
    sidebar: {
        opened: Cookies.get('sidebarStatus') ? !!+Cookies.get('sidebarStatus') : true,
        withoutAnimation: false
    },
    device: 'desktop',
    login_loading: true
}

const mutations = {
    TOGGLE_SIDEBAR: (state) => {
        state.sidebar.opened = !state.sidebar.opened
        state.sidebar.withoutAnimation = false
        if (state.sidebar.opened) {
            Cookies.set('sidebarStatus', 1)
        } else {
            Cookies.set('sidebarStatus', 0)
        }
    },
    CLOSE_SIDEBAR: (state, withoutAnimation) => {
        Cookies.set('sidebarStatus', 0)
        state.sidebar.opened = false
        state.sidebar.withoutAnimation = withoutAnimation
    },
    TOGGLE_DEVICE: (state, device) => {
        state.device = device
    },
    LOGIN_LOADING: (state, login_loading) => {
        state.login_loading = login_loading
    }
}

const actions = {
    toggleSideBar({ commit }) {
        commit('TOGGLE_SIDEBAR')
    },
    closeSideBar({ commit }, { withoutAnimation }) {
        commit('CLOSE_SIDEBAR', withoutAnimation)
    },
    toggleDevice({ commit }, device) {
        commit('TOGGLE_DEVICE', device)
    },
    openLoginLoading({ commit }) {
        commit('LOGIN_LOADING', false)
    },
    closeLoginLoading({ commit }) {
        commit('LOGIN_LOADING', true)
    }
}

export default {
    namespaced: true,
    state,
    mutations,
    actions
}
