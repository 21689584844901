import '@fortawesome/fontawesome-free/css/all.css'
import '@fortawesome/fontawesome-free/js/all.js'

import Vue from 'vue'
import App from './App'
import store from './store'
import router from './router'
import Cookies from 'js-cookie'

import './permission' // permission control
import filters from './filters'
import axios from 'axios'
import VueToast from 'vue-toast-notification'
import Logger from './utils/logger'
import { BootstrapVue, IconsPlugin, BootstrapVueIcons } from 'bootstrap-vue'
import { GridPlugin } from '@syncfusion/ej2-vue-grids'
import GoogleSignInButton from 'vue-google-signin-button-directive'
import VueCustomTooltip from '@adamdehaven/vue-custom-tooltip'
import {
    faInfoCircle,
    faCloudUploadAlt,
    faArrowsAltV,
    faTimesCircle,
    faUndo,
    faPlus,
    faEdit,
    faSave,
    faTimes,
    faGears,
    faSpinner,
    faExchange,
    faExclamationCircle,
    faDownload,
    faBan,
    faTrash
} from '@fortawesome/free-solid-svg-icons'
import { library } from '@fortawesome/fontawesome-svg-core'
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome'

library.add(
    faInfoCircle,
    faCloudUploadAlt,
    faArrowsAltV,
    faTimesCircle,
    faUndo,
    faPlus,
    faEdit,
    faSave,
    faTimes,
    faGears,
    faSpinner,
    faExchange,
    faExclamationCircle,
    faDownload,
    faBan,
    faTrash
)
Vue.component('fa-icon', FontAwesomeIcon)

Vue.prototype.axios = axios
Vue.use(Logger)
Vue.use(VueToast)
Vue.use(BootstrapVue)
Vue.use(IconsPlugin)
Vue.use(BootstrapVueIcons)
Vue.use(GridPlugin)
Vue.use(VueCustomTooltip)

Object.keys(filters).forEach((key) => {
    Vue.filter(key, filters[key])
})

import IdleVue from 'idle-vue'

const eventsHub = new Vue({
    el: '#app',
    router: router,
    store: store,
    GoogleSignInButton,
    render: (h) => h(App)
})

Vue.use(IdleVue, {
    eventEmitter: eventsHub,
    store,
    idleTime: 900000, // 15 minutes
    startAtIdle: false
    // render: h => h(App),
})

Vue.config.productionTip = false

Cookies.set('csi805ghqkm9', 'csdo2r4wcm6mel2nvpt9jd', {
    sameSite: 'Strict',
    secure: true
})
